@font-face {
  font-family: 'Geomanist';
  src: url(./assets/fonts/Geomanist-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Geomanist';
  font-weight: 600;
  src: url(./assets/fonts/hinted-Geomanist-Book.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(./assets/fonts/Inter-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(./assets/fonts/Inter-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  src: url(./assets/fonts/Manrope-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  src: url(./assets/fonts/Manrope-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url(./assets/fonts/Poppins-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 600;
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Sora';
  src: url(./assets/fonts/Sora-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Sora';
  font-weight: 600;
  src: url(./assets/fonts/Sora-Medium.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Geomanist, sans-serif; // Setting default font
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Hiding the scrollbar for all the browsers
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

svg path {
  stroke-width: 1.25;
}

/* All the common/shared styles will be here */
@import './styles/colors.scss';
@import './styles/mixins.scss';
@import './styles/common.scss';

/* All the libraries css will be here */
@import 'react-toastify/dist/ReactToastify.css';

/* All the components styles will be here */

