@tailwind base;
@tailwind components;
@tailwind utilities;

.font-Inter {
  font-family: 'Inter';
}
.font-Manrope {
  font-family: 'Manrope';
}
.font-Montserrat {
  font-family: 'Montserrat';
}
.font-Poppins {
  font-family: 'Poppins';
}
.font-Roboto {
  font-family: 'Roboto';
}
.font-Sora {
  font-family: 'Sora';
}

/*
Custom Css Classes
Note: 
1. Make sure to use below classes for all color needs instead of default tailwind classes.
2. Default states of all the classes are mentioned below. If you need to add some class then add them in their particular 
category as mentioned below.
3.Also if you are adding accent color somewhere then make sure to use respective primary classes like btn-primary for buttons
because in custom css we want to override are default accent color.
4. There are some commented classes below they notify that those classes are used but they are commented here as they have no
default value but they will be used in our custom css that we add(mostly these are those classes which have inline style of accent color).
5. Make sure to use BEM(http://getbem.com/naming/) naming convention
*/

/* Parent classes of whole app */
.main--background {
  @apply bg-gray-50;
}
.main__icon {
  @apply text-gray-800;
}
.divider {
  @apply border-gray-100;
}

/* Card classes */
.card {
  @apply bg-white;
}
.card__footer {
  @apply bg-gray-50;
}
.card__shimmer {
  @apply bg-gray-200;
}

/* Header classes */
.header {
  @apply bg-white;
}

/* These were used to show step color in verifyEmail, phoneNumber and create Account */
/* .form__step--active {
  accent-color
} */
.form__step {
  @apply border-gray-200;
}

/*
Text Classes
Note: If you have some color like text-gray-600 then you can use class closest to it like text__description insted.
*/
.text__title {
  @apply text-gray-900;
}
.text__body {
  @apply text-gray-800;
}
.text__description {
  @apply text-gray-500;
}
.text__info {
  @apply text-gray-400;
}
.text__header {
  @apply text-gray-900;
}
.text__link {
  @apply text-blue-500;
}

/* Button Classes */
.btn__primary {
  @apply text-white border border-transparent hover:border-white hover:border-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed;
}
.btn__primary:active {
  @apply border-white border-opacity-25 text-opacity-75;
}
.btn__primary:disabled {
  @apply border-none text-opacity-100;
}

.btn__outline__dashed {
  @apply text-gray-400 border-gray-400;
}

.btn__link {
  @apply text-gray-900;
}

.btn__light {
  @apply bg-white text-gray-800 border border-gray-300 hover:border-gray-400 disabled:opacity-50 disabled:cursor-not-allowed;
}
.btn__light:active {
  @apply border-gray-300 border-opacity-50 text-opacity-50;
}
.btn__light:disabled {
  @apply border-gray-300 border-opacity-100 text-opacity-100;
}

.btn__danger {
  @apply bg-red-500 text-white;
}
/* .btn__outline__primary {} */

/* Form Elements Classes */
.input {
  @apply focus:border-gray-500 focus:ring-gray-500 border-gray-300 text-gray-900 placeholder-gray-500;
}
.input__borderless {
  @apply placeholder-gray-500 text-gray-900;
}

.select__button {
  @apply bg-white border-gray-300 focus:ring-gray-500 focus:border-gray-500 text-gray-900;
}
.select__options__container {
  @apply bg-white border-gray-300 border-opacity-10;
}
.select__option {
  @apply text-gray-900;
}
.select__option--active {
  @apply bg-gray-100;
}

.menu__options__container {
  @apply bg-white border-gray-300 border-opacity-10;
}
.menu__option {
  @apply text-gray-900;
}
.menu__option--active {
  @apply bg-gray-100;
}

/* Table Classes */
.table__container {
  @apply border border-gray-200;
}
.table__head {
  @apply bg-gray-50 text-gray-500;
}
.table__body {
  @apply bg-white text-gray-500;
}

/* Hightlight class whereever you need a light background(and you do not want to use card) */
.highlighted {
  @apply bg-gray-100;
}

/* These text classes are defined here so that they can override above text colors */
.text__success {
  @apply text-green-500;
}
.text__danger {
  @apply text-red-500;
}

/* Chat Classes(Mostly all the elements of chat channel are given seperate classes from rest of app) */
.chat {
  @apply bg-white;
}
.chat__pinned {
  @apply bg-white;
}
.chat__pinned__divider {
  @apply bg-gray-800;
}
.chat__header__date {
  @apply bg-gray-100 text-gray-800;
}
.chat__sender__name {
  @apply text-gray-800;
}
.chat__sent__container {
  @apply bg-gray-100 border-gray-100;
}
.chat__sent__message {
  @apply text-white;
}
.chat__sent__time {
  @apply text-white;
}
.chat__received__container {
  @apply bg-gray-100 border-gray-100;
}
.chat__received__message {
  @apply text-gray-800;
}
.chat__received__time {
  @apply text-gray-400;
}
.chat__reply__container {
  @apply bg-gray-900 bg-opacity-40;
}
.chat__reply__name {
  @apply text-white;
}
.chat__reply__message {
  @apply text-white;
}
.chat__footer__container {
  @apply bg-white text-gray-900;
}
.chat__footer__btn {
  @apply bg-gray-800 bg-opacity-10 text-gray-700;
}
.chat__footer__container > textarea {
  @apply bg-gray-100 text-gray-900 placeholder-gray-500;
}

/* Stage Classes(Mostly Right panel in stage is given seperate classes from rest of app) */
.stage__rightpanel {
  @apply bg-white;
}
.stage__rightpanel__tab__container {
  @apply border-gray-200;
}
.stage__rightpanel__tab {
  @apply text-gray-500 hover:text-gray-700 border-gray-200 hover:border-gray-300;
}
.stage__rightpanel__tab--active {
  @apply border-gray-900 hover:border-gray-900 text-gray-900 hover:text-gray-900;
}
.stage__rightpanel__highlighted {
  @apply bg-gray-100;
}
.stage__rightpanel__title {
  @apply text-gray-900;
}
.stage__rightpanel__body {
  @apply text-gray-900;
}
.stage__rightpanel__info {
  @apply text-gray-500;
}
.stage__rightpanel__chat__footer__container {
  @apply bg-white text-gray-900;
}
.stage__rightpanel__chat__footer__btn {
  @apply bg-gray-800 bg-opacity-10 text-gray-700;
}
.stage__rightpanel__chat__footer__container > textarea {
  @apply bg-gray-100 text-gray-900 placeholder-gray-500;
}

/* Special Classes specific to some communities */
.signinwith__apple__gg {
  display: block;
}